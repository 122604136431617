<template>
  <div class="about">
    <v-container>
      <div>{{ items.length }}</div>
      <v-row>
        <v-col cols="12" v-for="(item, index) in newItems" :key="index">
          <v-card v-for="video in item.videos" :key="video.videoTitle">
            {{ video }},
          </v-card>
        </v-col>
      </v-row>
      <v-btn @click="handleClick">Click</v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      items: [
        {
          title: "happy birthday story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "orange",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "pink",
              imageURL: "",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "orange",
              imageURL: "",
              videoTitle: "big A little a(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Activity-2",
              color: "amber",
              imageURL: "",
              videoTitle: "big A little a(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "orange",
              imageURL: "",
              videoTitle: "big B little b(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "happy birthday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "no title",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
          ],
        },
        {
          title: "here is the beehive story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big B little b(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "big C little c(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big C little c(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "big D little d(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "here is the beehive story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "amber",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "here is the beehive song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "green",
              imageURL: "",
              videoTitle: "bumblebees(Stella)",
              videoURL:
                "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/90/vid/9001.mp4",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "walking walking",
              videoURL: "",
            },
          ],
        },
        {
          title: "fishy fishy in a brook story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big D little d(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "big E little e(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Phonics-13",
              color: "pink",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "pink",
              imageURL: "",
              videoTitle: "walk around the garden(Stella)",
              videoURL:
                "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/88/vid/8801.mp4",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big E little e(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "big F little f(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "fishy fishy in a brook story",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              imageURL: "",
              videoTitle: "bubbles bubbles bubbles(Stella)",
              videoURL:
                "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/86/vid/8601.mp4",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "fishy fishy in a brook song",
              videoURL: "",
            },
            {
              name: "Activity-2",
              color: "green",
              imageURL: "",
              videoTitle: "no title",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "wash my hands",
              videoURL: "",
            },
          ],
        },
        {
          title: "Jenny story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "cyan",
              imageURL: "",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "three little monkeys",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big F little f(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "The Alphabet song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "big G little g(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "three little monkeys",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big G little g(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "big H little h(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              imageURL: "",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "three little monkeys",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "Jenny story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "Jenny song",
              videoURL: "",
            },
            {
              name: "Activity",
              color: "green",
              imageURL: "",
              videoTitle: "",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
          ],
        },
        {
          title: "one potato two potato story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "i like to eat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big H little h(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Routine-9",
              color: "cyan",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "big I little i(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "i like to eat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "big J little j(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Activity-20",
              color: "amber",
              imageURL: "",
              videoTitle: "what do you see(k1-05)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "i like to eat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "one potato two potato story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "one potato two potato song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "potato sprouts(Stella)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
          ],
        },
        {
          title: "funny turkey story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big J little j(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Routine-9",
              color: "cyan",
              imageURL: "",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "big K little k(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "big K little k(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "big L little l(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              imageURL: "",
              videoTitle: "lets make a chicken sandwich",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big L little l(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "big M little m(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "funny turkey story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "funny turkey song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "green",
              imageURL: "",
              videoTitle: "memory game(k1-06)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "thank you song",
              videoURL: "",
            },
          ],
        },
        {
          title: "dance for your daddy story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "orange",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big M little m(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "grandmas house",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Routine-9",
              color: "cyan",
              imageURL: "",
              videoTitle: "thank you song 2",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "pink",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "big N little n(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "big N little n(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "big O little o(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "grandmas house",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big O little o(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "big P little p(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "dance for your daddy story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "dance for your daddy song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "watch the wheels",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
          ],
        },
        {
          title: "cobbler cobbler story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "use the potty",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big P little p(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Routine-9",
              color: "cyan",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "big Q little q(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "big Q little q(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "big R little r(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big R little r(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "big S little s(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "cobbler cobbler story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "cobbler cobbler song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "using kitchen tools",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
          ],
        },
        {
          title: "little Jack Horner story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "seasons song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big S little s(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Routine-9",
              color: "cyan",
              imageURL: "",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "big T little t(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "big U little u(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "seasons song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "big V little v(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little Jack Horner story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little Jack Horner song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "green",
              imageURL: "",
              videoTitle: "dress up game",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
          ],
        },
        {
          title: "one two three four five story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "friendship song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Phonics-8",
              color: "orange",
              imageURL: "",
              videoTitle: "big V little v(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Activity-8",
              color: "cyan",
              imageURL: "",
              videoTitle: "reuse",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "big W little w(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "cyan",
              imageURL: "",
              videoTitle: "big X little x(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "friendship song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "big Y little y(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Phonics-19",
              color: "amber",
              imageURL: "",
              videoTitle: "big Z little z(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "one two three four five story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "one two three four five song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "musical kitchen",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
          ],
        },
        {
          title: "ice cream story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "hello song daily routine",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "goodbye song daily routine",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "a says a(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "a says a(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "b says b(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "ice cream story",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              imageURL: "",
              videoTitle: "lets blow bubbles(Stella)",
              videoURL:
                "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/84/vid/8401.mp4",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "ice cream song",
              videoURL: "",
            },
            {
              name: "Activity",
              color: "green",
              imageURL: "",
              videoTitle: "",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
          ],
        },
        {
          title: "little miss Muffet story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "walking walking",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "c says c(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "c says c(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "d says d(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "teddy bear",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "amber",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "walking walking",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little miss Muffet story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little miss Muffet song",
              videoURL: "",
            },
            {
              name: "Activity",
              color: "green",
              imageURL: "",
              videoTitle: "",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "morning",
              videoURL: "",
            },
          ],
        },
        {
          title: "i am a little teapot story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "d says d(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "e says e(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "e says e(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Activity-18",
              color: "pink",
              imageURL: "",
              videoTitle: "f says f(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Activity-21",
              color: "amber",
              imageURL: "",
              videoTitle: "teapots with shapes",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "i am a little teapot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "i am a little teapot song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "lets blow bubbles(Stella)",
              videoURL:
                "https://iwonderdual.com:46322/2012/file/url_file/ea_stories/hangul/newc/84/vid/8401.mp4",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "hello song",
              videoURL: "",
            },
          ],
        },
        {
          title: "the muffin man story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "cyan",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "f says f(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "g says g(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "g says g(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "h says h(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "green",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "the muffin man story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "amber",
              imageURL: "",
              videoTitle: "memory game(k2-04)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "the muffin man song",
              videoURL: "",
            },
            {
              name: "Activity",
              color: "green",
              imageURL: "",
              videoTitle: "",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "open shut them",
              videoURL: "",
            },
          ],
        },
        {
          title: "little Tommy Tucker story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "i like to eat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "orange",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "h says h(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "i like to eat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "i says i(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "pink",
              imageURL: "",
              videoTitle: "dots and boxes",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "i says i(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "j says j(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "i like to eat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "little Tommy Tucker story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "little Tommy Tucker song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "beans grow",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
          ],
        },
        {
          title: "today is monday story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "days of the week song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "happy birthday",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "j says j(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "k says k(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "days of the week song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "k says k(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "l says l(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "amber",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "happy birthday",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "l says l(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "m says m(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "today is monday story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "today is monday song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "happy birthday",
              videoURL: "",
            },
          ],
        },
        {
          title: "five little men in a flying saucer story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "grandmas house",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "n says n(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "n says n(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "o says o(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "grandmas house",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "o says o(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "p says p(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Activity-22",
              color: "green",
              imageURL: "",
              videoTitle: "coding memory game",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "five little men in a flying saucer story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "five little men in a flying saucer song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "lets roll",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "go and stop",
              videoURL: "",
            },
          ],
        },
        {
          title: "peanut butter and jelly story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "months song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Activity-8",
              color: "cyan",
              imageURL: "",
              videoTitle: "Simon says(k2-08)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "q says q(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "use the potty",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "q says q(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "r says r(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "use the potty",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "r says r(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "s says s(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "use the potty",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "peanut butter and jelly story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "peanut butter and jelly song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "green",
              imageURL: "",
              videoTitle: "shape world",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
          ],
        },
        {
          title: "peas porridge hot story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "seasons song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Activity-8",
              color: "cyan",
              imageURL: "",
              videoTitle: "hot and cold",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "pink",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              imageURL: "",
              videoTitle: "t says t(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "pink",
              imageURL: "",
              videoTitle: "t says t(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "amber",
              imageURL: "",
              videoTitle: "u says u(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "amber",
              imageURL: "",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "amber",
              imageURL: "",
              videoTitle: "tic tac toe",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "green",
              imageURL: "",
              videoTitle: "u says u(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "v says v(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "peas porridge hot story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "peas porridge hot song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
          ],
        },
        {
          title: "the farmers story",
          videos: [
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              imageURL: "",
              videoTitle: "thank you song 2",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              imageURL: "",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              imageURL: "",
              videoTitle: "roll your hands",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Activity-8",
              color: "cyan",
              imageURL: "",
              videoTitle: "lets grow plants",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              imageURL: "",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "cyan",
              imageURL: "",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              imageURL: "",
              videoTitle: "w says w(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Phonics-11",
              color: "cyan",
              imageURL: "",
              videoTitle: "x says x(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              imageURL: "",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "pink",
              imageURL: "",
              videoTitle: "friendship song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              imageURL: "",
              videoTitle: "x says x(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              imageURL: "",
              videoTitle: "y says y(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              imageURL: "",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Phonics-19",
              color: "amber",
              imageURL: "",
              videoTitle: "y says y(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Phonics-19",
              color: "green",
              imageURL: "",
              videoTitle: "z says z(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              imageURL: "",
              videoTitle: "the farmers story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              imageURL: "",
              videoTitle: "the farmers song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "green",
              imageURL: "",
              videoTitle: "musical kitchen",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              imageURL: "",
              videoTitle: "manners",
              videoURL: "",
            },
          ],
        },
        {
          title: "who stole the cookies from the cookie jar story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "hello song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "The Alhpabet song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short a(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "ABC song",
              color: "pink",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "short a(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "the school",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "before and after",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            {
              name: "Activity-22",
              color: "green",
              videoTitle: "Simon says(k3-01)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "wiggle wiggle",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "who stole the cookies from the cookie jar story",
              videoURL: "",
            },
            {
              name: "Activity-1",
              color: "amber",
              videoTitle: "lets make bubbles(Stella)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "who stole the cookies from the cookie jar song",
              videoURL: "",
            },
            { name: "Activity", color: "green", videoTitle: "", videoURL: "" },
            {
              name: "Chant",
              color: "green",
              videoTitle: "head and shoulders",
              videoURL: "",
            },
          ],
        },
        {
          title: "chick chick chick chick chicken story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "goodbye song daily routine",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Activity-8",
              color: "cyan",
              videoTitle: "sound it out(k3-02)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "walking walking",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short a(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short e(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "walking walking",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "cyan",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Activity-12",
              color: "pink",
              videoTitle: "shapes(k3-02)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "pink",
              videoTitle: "tangram(k3-02)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "the school",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "the animal jumps",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "morning",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "green",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "animal song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Phonics-21",
              color: "amber",
              videoTitle: "the animal jumps",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "walking walking",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "chick chick chick chick chicken story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "chick chick chick chick chicken song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "green",
              videoTitle: "tangram(k3-02)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "goodbye song daily routine",
              videoURL: "",
            },
          ],
        },
        {
          title: "hot cross buns story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short i(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "cover your mouth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "cyan",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "amber",
              videoTitle: "make five game(Stella)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "cover your mouth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "the animal jumps",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "roll your hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "this is my family",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "wash my hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Activity-20",
              color: "amber",
              videoTitle: "make five card game(Stella)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "finger family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "green",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "happy family",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "hot cross buns story",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              videoTitle: "lets blow bubbles(Stella)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "hot cross buns song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "wash my hands",
              videoURL: "",
            },
          ],
        },
        {
          title: "the queen of hearts story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short i(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short o(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "cyan",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "this is my family",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "thank you song 2",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "i see fruits and vegetables",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "green",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "brush my teeth",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "clean up song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "the queen of hearts story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "amber",
              videoTitle: "my town",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "the queen of hearts song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
          ],
        },
        {
          title: "five crispy pancakes story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "months song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "feelings",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "short u(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "open shut them",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "cyan",
              videoTitle: "roll your hands",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "pink",
              videoTitle: "dots and boxes",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "i see fruits and vegetables",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "lets play",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Activity-20",
              color: "amber",
              videoTitle: "healthy ladder and slide",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "roll your hands",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "green",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "five crispy pancakes story",
              videoURL: "",
            },
            {
              name: "Activity-22",
              color: "amber",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "five crispy pancakes song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "happy birthday",
              videoURL: "",
            },
          ],
        },
        {
          title: "autumn festival story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "months song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Activity-8",
              color: "cyan",
              videoTitle: "hello friends",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "cyan",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              videoTitle: "short u(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "thank you song 2",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "long a(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "cyan",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "happy birthday",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              videoTitle: "how to make songpyeon",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "lets play",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "celebration",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "yummy fruits",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "amber",
              videoTitle: "clean up song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "green",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "mr Geneus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "autumn festival story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "autumn festival song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
          ],
        },
        {
          title: "Aiken drum story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "grandmas house",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "cyan",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "long i(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              videoTitle: "numbers song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "grandmas house",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "pink",
              videoTitle: "friendship song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "celebration",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "go and stop",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "transportation",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "one little finger",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "the wheels on the bus",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Activity-22",
              color: "amber",
              videoTitle: "",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "green",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "cross the street",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "Aiken drum story",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              videoTitle: "fast or slow",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "Aiken drum song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "go and stop",
              videoURL: "",
            },
          ],
        },
        {
          title: "found a peanut story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "cyan",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              videoTitle: "long i(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "long o(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "use the potty",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Activity-12",
              color: "pink",
              videoTitle: "measuring",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "transportation",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "use the potty",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "tools and machines",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "this is the way",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "friendship song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "in the kitchen",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Activity-2",
              color: "green",
              videoTitle: "thank you song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "sorry excuse me",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "found a peanut story",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "amber",
              videoTitle: "manners can be fun",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "found a peanut song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "use the potty",
              videoURL: "",
            },
          ],
        },
        {
          title: "i am a super cook story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Routine-3",
              color: "orange",
              videoTitle: "seasons song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Routine-8",
              color: "orange",
              videoTitle: "shapes song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "cyan",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              videoTitle: "long o(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "long u(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Routine-12",
              color: "cyan",
              videoTitle: "seasons song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "amber",
              videoTitle: "goodbye song daily routine",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "merry christmas",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "The Alphabet Song",
              color: "pink",
              videoTitle: "the alphabet song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "tools and machines",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "special days",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "stretching song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "rain rain go away",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Activity-16",
              color: "green",
              videoTitle: "alphabet memory game",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "pat pat pat",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "i am a super cook story",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              videoTitle: "rub the balloon",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "i am a super cook song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "friendship song",
              videoURL: "",
            },
          ],
        },
        {
          title: "if all the world story",
          videos: [
            {
              name: "Story",
              color: "orange",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Routine-13",
              color: "orange",
              videoTitle: "friendship song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Routine-4",
              color: "orange",
              videoTitle: "the weather song",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "orange",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "cyan",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "cyan",
              videoTitle: "colors song",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "pink",
              videoTitle: "long u(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Phonics-10",
              color: "cyan",
              videoTitle: "long vowels(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "cyan",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "pink",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "pink",
              videoTitle: "roll your hands",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "long a(chant)",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "amber",
              videoTitle: "long i(chant)",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
            {
              name: "Story",
              color: "pink",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Phonics-18",
              color: "pink",
              videoTitle: "the earth",
              videoURL: "",
            },
            {
              name: "Song",
              color: "amber",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "amber",
              videoTitle: "manners",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Routine",
              color: "green",
              videoTitle: "clean up song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "the green string",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "ABC Song",
              color: "green",
              videoTitle: "ABC song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "i love earth",
              videoURL: "",
            },
            {
              name: "Story",
              color: "amber",
              videoTitle: "if all the world story",
              videoURL: "",
            },
            {
              name: "Activity-14",
              color: "amber",
              videoTitle: "music maker",
              videoURL: "",
            },
            {
              name: "Song",
              color: "green",
              videoTitle: "if all the world song",
              videoURL: "",
            },
            {
              name: "Chant",
              color: "green",
              videoTitle: "twinkle twinkle little star",
              videoURL: "",
            },
          ],
        },
      ],
      newItems: [],
    };
  },
  methods: {
    handleClick() {
      const newItems = this.items.map((item) => {
        const newArray = item.videos.filter((arr, index, callback) => {
          return (
            index === callback.findIndex((t) => t.videoTitle === arr.videoTitle)
          );
        });
        return {
          title: item.title,
          videos: newArray,
        };
      });

      this.newItems = newItems;
      console.log(this.newItems);
    },
  },
};
</script>
